import get from 'lodash/get'
import head from 'lodash/head'
import sortBy from 'lodash/sortBy'
import { Job, Jobs } from './types'
import { isLocale } from './utils'

export const getId = (id: string) => {
  // No idea why there is a prefix.
  return id.replace('Greenhouse__Job__', '')
}

export const getLocation = (location: string) => {
  return location.replace('Greater Poland Voivodeship, ', '')
}

const formatDigits = (n: number) => {
  return n
    .toString()
    .split('.', 1)[0]
    .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
}

export const getSalary = (job: Job) => {
  const salary = job.metadata.find((item) => item.name === 'Salary Range')

  if (!salary) {
    return ''
  }

  const { min_value, max_value, unit } = salary.value
  const min = parseInt(min_value)
  const max = parseInt(max_value)

  if (!(min > 0 && max > 0) || !unit) {
    return ''
  }

  const range = `${formatDigits(min)} - ${formatDigits(max)}`
  const vat = unit === 'PLN' ? ' + VAT' : ''

  return `${range} ${unit}${vat}`
}

export const getContractorData = (job: Job) => {
  const data = job.metadata.find((item) => item.name === 'Contractor')

  if (!data) {
    return ''
  }

  return data.text
}

export const sortByRegion = (jobs: Jobs): Jobs => {
  let isPolishLanguage = false

  if (typeof navigator !== 'undefined') {
    isPolishLanguage = isLocale('pl')
  }

  return sortBy(jobs, (job: Job) => {
    const isPolishCurrency = get(head(job.metadata), ['value', 'unit']) === 'PLN'
    const isPolishLocation = get(job, ['location', 'name']).includes('Poznań')

    return isPolishLanguage
      ? !isPolishLocation && !isPolishCurrency
      : isPolishLocation || isPolishCurrency
  })
}
