import { Link } from 'gatsby'
import unescape from 'lodash/unescape'
import React, { useState } from 'react'
import {
  getLocation,
  getSalary,
  getContractorData,
  Job as JobType,
} from '../utils/greenhouse'

type Props = {
  job: JobType
}

export const Job = ({ job }: Props) => {
  const title = job.title
  const content = unescape(job.content)
  const salary = getSalary(job)
  const contractorData = getContractorData(job)
  const location = getLocation(job.location.name)

  return (
    <div className="section-full-article">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12 col-lg-10">
            <div className="row">
              <div className="col-12">
                <div className="back-to-main">
                  <Link to="/#positions">
                    <svg
                      width={31}
                      height={30}
                      viewBox="0 0 31 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="arrow-left"
                    >
                      <path
                        d="M14.6464 27.6036L15.3536 28.3107L14.6464 29.0178L13.9393 28.3107L14.6464 27.6036ZM2.39645 15.3536L1.68934 16.0607L1.68934 16.0607L2.39645 15.3536ZM15.3536 26.8964L16.0607 26.1893L16.7678 26.8964L16.0607 27.6036L15.3536 26.8964ZM3.45711 15L2.75 15.7071L2.04289 15L2.75 14.2929L3.45711 15ZM15.3536 3.10355L16.0607 2.39645L16.7678 3.10355L16.0607 3.81066L15.3536 3.10355ZM14.6464 2.39645L13.9393 1.68934L14.6464 0.982232L15.3536 1.68934L14.6464 2.39645ZM2.39645 14.6464L1.68934 13.9393L1.68934 13.9393L2.39645 14.6464ZM2.04289 15L1.33579 15.7071L0.628682 15L1.33579 14.2929L2.04289 15ZM29.5522 14.5V13.5H30.5522V14.5H29.5522ZM2.25 14.5H1.25V13.5H2.25V14.5ZM2.25 15.5V16.5H1.25V15.5H2.25ZM29.5522 15.5H30.5522V16.5H29.5522V15.5ZM13.9393 28.3107L1.68934 16.0607L3.10355 14.6464L15.3536 26.8964L13.9393 28.3107ZM16.0607 27.6036L15.3536 28.3107L13.9393 26.8964L14.6464 26.1893L16.0607 27.6036ZM4.16421 14.2929L16.0607 26.1893L14.6464 27.6036L2.75 15.7071L4.16421 14.2929ZM16.0607 3.81066L4.16421 15.7071L2.75 14.2929L14.6464 2.39645L16.0607 3.81066ZM15.3536 1.68934L16.0607 2.39645L14.6464 3.81066L13.9393 3.10355L15.3536 1.68934ZM1.68934 13.9393L13.9393 1.68934L15.3536 3.10355L3.10355 15.3536L1.68934 13.9393ZM1.33579 14.2929L1.68934 13.9393L3.10355 15.3536L2.75 15.7071L1.33579 14.2929ZM1.68934 16.0607L1.33579 15.7071L2.75 14.2929L3.10355 14.6464L1.68934 16.0607ZM29.0522 13.5H29.5522V15.5H29.0522V13.5ZM2.75 13.5H29.0522V15.5H2.75V13.5ZM2.25 13.5H2.75V15.5H2.25V13.5ZM1.25 15.5V14.5H3.25V15.5H1.25ZM2.75 16.5H2.25V14.5H2.75V16.5ZM29.0522 16.5H2.75V14.5H29.0522V16.5ZM29.5522 16.5H29.0522V14.5H29.5522V16.5ZM30.5522 14.5V15.5H28.5522V14.5H30.5522Z"
                        fill="#10110D"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8">
                <div className="main-article">
                  <article>
                    <div className="article-header">
                      <h1 className="article-title">{title}</h1>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                  </article>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="side-bar">
                  <aside>
                    <div className="sidebar-content-box">
                      <ul>
                        {salary && (
                          <li>
                            <div className="form-field-custom">
                              <p className="label">SALARY</p>
                              <p>{salary}</p>
                            </div>
                          </li>
                        )}
                        {contractorData && (
                          <li>
                          <div className="form-field-custom">
                            <p className="label">SALARY</p>
                            <p>{contractorData}</p>
                          </div>
                        </li>
                        )}
                        {location && (
                          <li>
                            <div className="form-field-custom">
                              <p className="label">LOCATION</p>
                              <p>{location}</p>
                            </div>
                          </li>
                        )}
                        <li>
                          <div className="form-field-custom">
                            <a
                              className="btn btn-primary"
                              href={job.emailApply ? 'mailto:jobs@fourthwall.com' : 'https://fourthwallhq.typeform.com/careers'}
                              target={job.emailApply ? '_self' : '_blank' }
                            >
                              Apply now
                            </a>

                            <div className="description-container">
                              <span className="description">Send CV & portfolio to <strong>jobs@fourthwall.com</strong></span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
