import React from 'react'
import { Job } from '../components/Job'
import { SEO } from '../components/SEO'
import { JobLayout } from '../layouts/JobLayout'
import { getLocation, Job as JobType } from '../utils/greenhouse'

type Props = {
  pageContext: {
    job: JobType
  }
}

const JobPageTemplate = ({ pageContext }: Props) => (
  <JobLayout>
    <SEO title={`${pageContext.job.title} - ${getLocation(pageContext.job.location.name)}`} ogImage={pageContext.job.ogImage} />
    <Job job={pageContext.job} />
  </JobLayout>
)

export default JobPageTemplate
